import VkIcon from '../../../assets/icons/VK.svg';
import OkIcon from '../../../assets/icons/Ok.svg';
import TgIcon from '../../../assets/icons/TG.svg';
import cls from './ShareComponent.module.scss';
import { VKShareButton, OKShareButton, TelegramShareButton } from 'react-share';

export const ShareComponent = () => {
  const shareData = {
    title: 'Мимесис',
    text: 'Зачем читать про события, если можно в них играть!',
    url: 'https://beta.memesis.ru/',
  };

  const shareSocial = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  };

  return (
    <div>
      <div className={cls.shareText} onClick={shareSocial}>
        Поделиться
      </div>
      <div className={cls.socialIcons}>
        <VKShareButton title="Мимесис" url="https://beta.memesis.ru/">
          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_909_36414)">
              <path d="M13.3204 17.6589H11.0102C10.5084 17.6589 10.3819 18.262 10.608 18.8176C10.834 19.3731 13.8644 26.4803 16.8872 29.1946C19.9061 31.9089 21.7987 31.9485 24.1127 31.9843C26.6298 32.0239 24.7295 28.885 26.1585 28.6549C27.7714 28.3969 28.5568 31.6073 30.6218 31.9962C30.6218 31.9962 34.3686 31.9962 34.7211 31.9962C35.0736 31.9962 36.8665 31.7303 35.4835 29.9723C34.1005 28.2144 32.1887 26.3454 31.9895 26.0636C31.7903 25.7819 31.4608 25.4009 31.8976 24.8255C32.3343 24.2541 35.2651 19.9565 35.6099 19.3136C35.9547 18.6747 36.3378 17.6549 35.154 17.6549H30.8287C30.0586 17.6549 30.0471 17.6033 28.7714 20.6311C27.3883 23.9168 25.9823 24.9287 25.4344 23.885C25.1893 23.4168 25.4 18.1628 25.3272 17.9525C25.2544 17.7462 25.2007 16.9922 23.9633 16.9922C20.1245 16.9922 19.5537 17.016 18.9483 17.7938C18.4311 18.4565 20.4387 17.6708 20.4387 19.7263C20.4387 22.0239 20.5459 23.8493 19.9751 24.2104C19.4042 24.5755 18.4043 24.0795 16.2627 19.3096C15.4544 17.5081 15.7953 17.6589 13.3204 17.6589Z" fill="currentColor"/>
            </g>
            <rect x="1.33333" y="1.83333" width="45.3333" height="45.3333" stroke="currentColor" strokeWidth="2.66667"/>
            <defs>
              <clipPath id="clip0_909_36414">
                <rect width="25.5" height="15" fill="currentColor" transform="translate(10.5 17)"/>
              </clipPath>
            </defs>
          </svg>
        </VKShareButton>
        <OKShareButton
          title="Мимесис"
          description="Зачем читать про события, если можно в них играть!"
          url="https://beta.memesis.ru/">
          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_909_36417)">
              <path d="M24.7497 24.5751C28.3171 24.5751 31.2143 21.5298 31.2143 17.7897C31.2143 14.0496 28.313 11 24.7497 11C21.1865 11 18.2852 14.0453 18.2852 17.7854C18.2852 21.5255 21.1865 24.5708 24.7497 24.5708V24.5751ZM24.7497 14.1911C26.6376 14.1911 28.1782 15.8038 28.1782 17.7897C28.1782 19.7755 26.6417 21.3882 24.7497 21.3882C22.8578 21.3882 21.3213 19.7755 21.3213 17.7897C21.3213 15.8038 22.8578 14.1911 24.7497 14.1911Z" fill="currentColor"/>
              <path d="M34.3195 26.1187C33.8741 25.085 32.7176 24.6303 31.7328 25.0979C29.5303 26.1444 27.1807 26.672 24.7411 26.672C22.3016 26.672 19.9519 26.1401 17.7494 25.0979C16.7646 24.6303 15.6082 25.0893 15.1627 26.1187C14.7173 27.1523 15.1546 28.3662 16.1353 28.8337C17.6799 29.5671 19.2859 30.0947 20.9367 30.4121L17.3898 34.5854C16.6706 35.4303 16.7442 36.7257 17.5492 37.4805C18.3542 38.2354 19.5882 38.1582 20.3074 37.3133L24.737 32.0977L29.1666 37.3133C29.5507 37.7679 30.086 37.9995 30.6254 37.9995C31.0872 37.9995 31.553 37.828 31.9249 37.4805C32.7299 36.7257 32.7994 35.4303 32.0843 34.5854L28.5373 30.4121C30.1882 30.0947 31.79 29.5671 33.3388 28.8337C34.3236 28.3662 34.7567 27.1523 34.3113 26.1187H34.3195Z" fill="currentColor"/>
            </g>
            <rect x="1.33333" y="1.83333" width="45.3333" height="45.3333" stroke="currentColor" strokeWidth="2.66667"/>
            <defs>
              <clipPath id="clip0_909_36417">
                <rect width="19.5" height="27" fill="currentColor" transform="translate(15 11)"/>
              </clipPath>
            </defs>
          </svg>

        </OKShareButton>
        <TelegramShareButton title="Мимесис" url="https://beta.memesis.ru/">
          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_909_36421)">
              <path d="M25.2857 30.6669C27.2254 31.96 29.6932 33.5672 31.1313 34.2852C32.5694 35.0031 34.0697 35.6721 34.6112 33.6447C35.1527 31.6133 37.4874 16.2549 37.4874 15.6022C37.4874 14.9495 37.6117 14.6069 36.9858 14.1867C36.36 13.7665 35.0284 14.15 34.3582 14.4151C33.6924 14.6844 14.1226 22.2391 12.4537 22.9775C10.8736 23.6791 10.5185 23.8749 10.5007 24.2339C10.4741 24.8662 11.1887 25.0905 11.4817 25.2007C11.8545 25.3435 16.1289 26.7182 17.0832 26.7345C18.0375 26.7508 18.8409 26.2939 19.7153 25.7187C20.5897 25.1436 29.9107 19.5509 30.3235 19.351C30.6653 19.1879 30.905 19.2246 31.0514 19.3959C31.2157 19.5835 31.1047 19.8691 30.8206 20.1139C30.541 20.3586 23.2883 26.7834 22.8178 27.2322C22.3118 27.7176 22.2852 28.1214 22.6181 28.5701C22.9332 28.9944 25.2901 30.671 25.2901 30.671L25.2857 30.6669Z" fill="currentColor"/>
            </g>
            <rect x="1.33333" y="1.83333" width="45.3333" height="45.3333" stroke="currentColor" strokeWidth="2.66667"/>
            <defs>
              <clipPath id="clip0_909_36421">
                <rect width="27" height="21" fill="currentColor" transform="translate(10.5 14)"/>
              </clipPath>
            </defs>
          </svg>

        </TelegramShareButton>
      </div>
    </div>
  );
};
