import cls from './MimesisComponent.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setCategoryId } from '../../../shared/redux/games/slice';
import { WindowIcon } from '../../../shared/ui/WindowIcon';
import NYTree from '../../../shared/assets/icons/NY-tree.svg';
import NYSanta from '../../../shared/assets/icons/NY-santa.svg';
import NYSnowMan from '../../../shared/assets/icons/NY-snowman.svg';
import { isNY } from '../../../features/isNY';
import { useTheme } from '../../../app/providers/ThemeProvider';
import { Theme } from '../../../app/providers/ThemeProvider/lib/ThemeContext';
import { RootState } from '../../../shared/redux/store';
import { Category } from '../../../shared/redux/games/types';

export const MimesisComponent = () => {
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const categoryId = useSelector((state: RootState) => state.games.categoryId);

  return (
    <div className={cls.MimesisComponent}>
      <div className={cls.container}>
        <div className={cls.header}>
          <div className={cls.headerItem}>
            <WindowIcon />
            <h1>Мимесиc</h1>
            {isNY() && theme === Theme.DARK ? <img src={NYTree} className={cls.NYTree} alt={""} /> : <></>}
            {isNY() && theme === Theme.DARK ? <img src={NYSanta} className={cls.NYSanta} alt={""} /> : <></>}
            {isNY() && theme === Theme.DARK ? <img src={NYSnowMan} className={cls.NYSnowMan} alt={""} /> : <></>}
          </div>
          <svg className={cls.CloseIcon} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="5.14062" y="5.14062" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="10.2812" y="10.2832" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="15.4258" y="15.4297" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="20.5742" y="20.5703" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="20.5742" y="10.2832" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="25.7188" y="5.14062" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="30.8594" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="10.2812" y="20.5703" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="5.14062" y="25.7168" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect y="30.8594" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="25.7188" y="25.7168" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="30.8594" y="30.8594" width="5.14286" height="5.14286" fill="currentColor"/>
          </svg>
        </div>
        <div>
          <div className={cls.changeCounrty}>
            <div
              onClick={() =>
                dispatch(
                  //@ts-ignore
                  setCategoryId(`${Category.ALL}`),
                )
              }
              className={categoryId === `${Category.ALL}` ? `${cls.active}` : ''}>
              Все
            </div>
            <div
              onClick={() =>
                dispatch(
                  //@ts-ignore
                  setCategoryId(`${Category.RUSSIA}`),
                )
              }
              className={
                categoryId === `${Category.RUSSIA}`
                  ? `${cls.active} ${cls.secondChild}`
                  : `${cls.secondChild}`
              }>
              Россия
            </div>
            <div
              onClick={() =>
                dispatch(
                  //@ts-ignore
                  setCategoryId(`${Category.INTERNATIONAL}`),
                )
              }
              className={categoryId === `${Category.INTERNATIONAL}` ? `${cls.active}` : ''}>
              Международные
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
