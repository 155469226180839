import { useTheme } from '../../../../app/providers/ThemeProvider';
import { Theme } from '../../../../app/providers/ThemeProvider/lib/ThemeContext';
import cls from './ThemeSwitcher.module.scss';

export const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();
  const toggleText = theme === Theme.LIGHT ? 'ON' : 'OFF';
  return (
    <div className={cls.ThemeSwitcher}>
      <div className={cls.toggleText}>{toggleText}</div>
      <div className={cls.switcherGroup} onClick={toggleTheme}>
          <svg className={cls.switch} viewBox="0 0 93 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="11.1797" width="3.57692" height="17.8846" fill="currentColor"/>
              <rect x="93" y="29.0625" width="3.57692" height="17.8846" transform="rotate(-180 93 29.0625)" fill="currentColor"/>
              <rect x="3.57422" y="7.60352" width="3.57692" height="3.57692" fill="currentColor"/>
              <rect x="89.4258" y="32.6406" width="3.57692" height="3.57692" transform="rotate(-180 89.4258 32.6406)" fill="currentColor"/>
              <rect x="3.57422" y="29.0625" width="3.57692" height="3.57692" fill="currentColor"/>
              <rect x="89.4258" y="11.1797" width="3.57692" height="3.57692" transform="rotate(-180 89.4258 11.1797)" fill="currentColor"/>
              <rect x="85.8438" y="7.60352" width="3.57692" height="3.57692" transform="rotate(-180 85.8438 7.60352)" fill="currentColor"/>
              <rect x="10.7344" y="36.2188" width="71.5385" height="3.57692" fill="currentColor"/>
              <rect x="7.15625" y="4.02734" width="3.57692" height="3.57692" fill="currentColor"/>
              <rect x="85.8438" y="36.2188" width="3.57692" height="3.57692" transform="rotate(-180 85.8438 36.2188)" fill="currentColor"/>
              <rect x="10.7344" y="0.449219" width="71.5385" height="3.57692" fill="currentColor"/>
              <rect x="7.15625" y="32.6406" width="3.57692" height="3.57692" fill="currentColor"/>
          </svg>

          <svg className={cls.circle} viewBox="0 0 26 27" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <rect x="10.625" y="0.792969" width="4.25" height="23.375" fill="currentColor"/>
              <rect x="2.125" y="2.91797" width="4.25" height="17" fill="currentColor"/>
              <rect x="6.375" y="0.792969" width="4.25" height="21.25" fill="currentColor"/>
              <rect x="14.875" y="0.792969" width="4.25" height="21.25" fill="currentColor"/>
              <rect x="19.125" y="2.91797" width="4.25" height="17" fill="currentColor"/>
              <rect x="19.125" y="19.918" width="4.25" height="4.25" fill="currentColor"/>
              <rect x="2.125" y="19.918" width="4.25" height="4.25" fill="currentColor"/>
              <rect x="6.375" y="22.043" width="4.25" height="4.25" fill="currentColor"/>
              <rect x="14.875" y="22.043" width="4.25" height="4.25" fill="currentColor"/>
              <rect y="15.668" width="4.25" height="4.25" fill="currentColor"/>
              <rect x="21.25" y="15.668" width="4.25" height="4.25" fill="currentColor"/>
              <rect x="21.25" y="7.16797" width="4.25" height="4.25" fill="currentColor"/>
              <rect y="7.16797" width="4.25" height="4.25" fill="currentColor"/>
              <rect x="10.625" y="22.043" width="4.25" height="4.25" fill="currentColor"/>
              <rect y="11.418" width="4.25" height="4.25" fill="currentColor"/>
              <rect x="21.25" y="11.418" width="4.25" height="4.25" fill="currentColor"/>
          </svg>


      </div>
    </div>
  );
};
