import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GamesItem } from '../games/types';
import { ChooseGameSliceState } from './types';

const initialState: ChooseGameSliceState = {
  isVisible: false,
  game: {
    id: 0,
    name: '',
    img: '',
    category: 0,
    short_description: '',
    description: '',
    link_read: '',
    link_play: '',
  },
};

export const chooseGame = createSlice({
  name: 'chooseGame',
  initialState,
  reducers: {
    setIsVisible(state, action: PayloadAction<boolean>) {
      state.isVisible = action.payload;
    },
    setChooseGame(state, action: PayloadAction<GamesItem>) {
      state.game = action.payload;
    },
  },
});

export const { setIsVisible, setChooseGame } = chooseGame.actions;

export default chooseGame.reducer;
