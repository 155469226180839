import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchGames } from './asyncActions';
import { GamesSliceState, Status, GamesItem, Category } from './types';

const initialState: GamesSliceState = {
  games: [],
  categoryId: Category.ALL,
  status: Status.LOADING,
};

export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setGames(state, action: PayloadAction<GamesItem[]>) {
      state.games = action.payload;
    },
    setCategoryId(state, action: PayloadAction<string>) {
      state.categoryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGames.pending, (state) => {
      state.status = Status.LOADING;
      state.games = [];
    });
    builder.addCase(fetchGames.fulfilled, (state, action) => {
      state.games = action.payload;
      state.status = Status.SUCCESS;
    });
    builder.addCase(fetchGames.rejected, (state) => {
      state.status = Status.ERROR;
      state.games = [];
    });
  },
});

export const { setGames, setCategoryId } = gamesSlice.actions;

export default gamesSlice.reducer;
