import { useDispatch } from 'react-redux';
import { setIsVisible, setChooseGame } from '../../../redux/chooseGame/slice';
import { GamesItem } from '../../../redux/games/types';
import cls from './GameItem.module.scss';

export const GameItem = (props: GamesItem) => {
  const { name, img } = props;
  const dispatch = useDispatch();
  const setChoise = () => {
    dispatch(setIsVisible(true));
    dispatch(setChooseGame(props));
  };
  const isTelegram = process.env.REACT_APP_TARGET === 'telegram';
  return (
    <a
      className={cls.gameItem}
      // onClick={() => setChoise()}
      href={props.link_play}
      target={isTelegram ? '_self' : '_blank'}>
      <div className={cls.gameAvatar}>
        <img src={img} />
      </div>
      <div className={cls.gameName}>{name}</div>
    </a>
  );
};
