export interface GamesSliceState {
  games: GamesItem[];
  categoryId: string;
  status: Status;
}

export type GamesItem = {
  id: number;
  name: string;
  img: string;
  category: number;
  short_description: string;
  description: string;
  link_read: string;
  link_play: string;
};

export enum Status {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum Category {
  ALL = 'all',
  RUSSIA = 'russia',
  INTERNATIONAL = 'international',
}
