import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Category, GamesItem } from './types';

export const fetchGames = createAsyncThunk(
  'games/fetchGamesStatus',
  async (category: Category, thunkAPI) => {
    const { data } = await axios.get<GamesItem[]>(`/api/games.json`);
    if (category === Category.ALL) {
      return data as GamesItem[];
    } else if (category === Category.RUSSIA) {
      return data.filter((el) => el.category === 0);
    } else {
      return data.filter((el) => el.category === 1);
    }
  },
);
