import cls from './GamesComponent.module.scss';
import FolderIcon from '../../../shared/assets/icons/Folder.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../shared/redux/store';
import { GameItem } from '../../../shared/ui/GameItem';
import { ScrollBar } from '../../../shared/ui/ScrollBar';
import { fetchGames } from '../../../shared/redux/games/asyncActions';
import { GamesItem } from '../../../shared/redux/games/types';

export const GamesComponent = () => {
  const games = useSelector((state: RootState) => state.games.games);
  const [reverseGames, setReverseGames] = useState<GamesItem[]>([]);
  const categoryId = useSelector((state: RootState) => state.games.categoryId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      //@ts-ignore
      fetchGames(categoryId),
    );
  }, [categoryId]);

  useEffect(() => {
    if (games) {
      const newGames = [...games].reverse();
      setReverseGames(newGames);
    }
  }, [games]);
  return (
    <div className={cls.GameComponent}>
      <div className={cls.header}>
        <div className={cls.headerItem}>
          <img src={FolderIcon} className={cls.folderIcon} />
          <h2>Игры</h2>
        </div>
        <svg
          className={cls.CloseIcon}
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="5.14062" y="5.14062" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="10.2812" y="10.2832" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="15.4258" y="15.4297" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="20.5742" y="20.5703" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="20.5742" y="10.2832" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="25.7188" y="5.14062" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="30.8594" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="10.2812" y="20.5703" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="5.14062" y="25.7168" width="5.14286" height="5.14286" fill="currentColor" />
          <rect y="30.8594" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="25.7188" y="25.7168" width="5.14286" height="5.14286" fill="currentColor" />
          <rect x="30.8594" y="30.8594" width="5.14286" height="5.14286" fill="currentColor" />
        </svg>
      </div>

      <ScrollBar>
        {reverseGames.map((el) => (
          <GameItem key={el.id} {...el} />
        ))}
      </ScrollBar>
    </div>
  );
};
