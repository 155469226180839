export const isNY = () => {
  const now = new Date();
  const month = now.getMonth();
  const day = now.getDate();
  const isDecemner = month === 11 && day > 15;
  const isJanuary = month === 0 && day < 15;
  if (isDecemner || isJanuary) {
    return true;
  } else {
    return false;
  }
};
