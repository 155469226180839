import { useCallback, useEffect, useRef, useState } from 'react';
import cls from './ScrollBar.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export const ScrollBar = ({ children, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);
  const [thumbHeight, setThumbHeight] = useState(100);
  const [thumpTopPosition, setThumpTopPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const games = useSelector((state: RootState) => state.games);
  const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(null);

  function handleResize(ref: HTMLDivElement, trackSize: number) {
    const { clientHeight, scrollHeight } = ref;
    setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, 10));
  }

  function handleScrollButton(direction: 'up' | 'down') {
    const { current } = contentRef;
    if (current) {
      const scrollAmount = direction === 'down' ? 150 : -150;
      current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
    }
  }

  const handleThumbPosition = useCallback(() => {
    const contentTop = contentRef.current?.scrollTop || 0;
    const contentHeight = contentRef.current?.scrollHeight || 0;
    const trackHeight = scrollTrackRef.current?.clientHeight || 0;
    const newTop = (contentTop / contentHeight) * trackHeight;
    setThumpTopPosition(newTop);
  }, []);

  const handleThumbMousedown = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollStartPosition(e.clientY);
    if (contentRef.current) setThumpTopPosition(contentRef.current.scrollTop);
    setIsDragging(true);
    console.log('mouse down');
  }, []);

  const handleThumbMouseup = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging],
  );

  const handleThumbMousemove = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        const contentScrollHeight = contentRef.current?.scrollHeight || 0;
        const contentOffsetHeight = contentRef.current?.offsetHeight || 0;

        const deltaY =
          (e.clientY - Number(scrollStartPosition)) * (contentOffsetHeight / thumbHeight);
        const newScrollTop = Math.min(
          thumpTopPosition + deltaY,
          contentScrollHeight - contentOffsetHeight,
        );

        if (contentRef.current !== null) contentRef.current.scrollTop = newScrollTop;
      }
    },
    [isDragging, scrollStartPosition, thumbHeight],
  );

  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientHeight: trackSize } = scrollTrackRef.current;
      observer.current = new ResizeObserver(() => {
        handleResize(ref, trackSize);
      });
      observer.current.observe(ref);
      ref.addEventListener('scroll', handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener('scroll', handleThumbPosition);
      };
    }
  }, [games]);

  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove);
    document.addEventListener('mouseup', handleThumbMouseup);
    document.addEventListener('mouseleave', handleThumbMouseup);
    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove);
      document.removeEventListener('mouseup', handleThumbMouseup);
      document.removeEventListener('mouseleave', handleThumbMouseup);
    };
  }, [handleThumbMousemove, handleThumbMouseup]);

  return (
    <div className={cls.customScrollbarsContainer}>
      <div className={cls.customScrollbarsContent} {...props} ref={contentRef}>
        {children}
      </div>
      <div className={cls.customScrollbarsScrollbar}>
        <button
          className={cls.customScrollbarsButtonFrist}
          onClick={() => handleScrollButton('up')}></button>
        <div className={cls.customScrollbarsTrackAndThumb} ref={scrollTrackRef}>
          <div className={cls.customScrollbarsTrack}></div>
          <div
            className={cls.customScrollbarsThumb}
            style={{ height: thumbHeight, top: thumpTopPosition }}
            onMouseDown={(ev) => handleThumbMousedown(ev)}
            ref={scrollThumbRef}></div>
        </div>
        <button
          className={cls.customScrollbarsButtonSecond}
          onClick={() => handleScrollButton('down')}></button>
      </div>
    </div>
  );
};
