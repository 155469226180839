import cls from './GameInfo.module.scss';
import FolderIcon from '../../../shared/assets/icons/Folder.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setIsVisible } from '../../../shared/redux/chooseGame/slice';
import { RootState } from '../../../shared/redux/store';

export const GameInfo = () => {
  const dispatch = useDispatch();
  const chooseGame = useSelector((state: RootState) => state.chooseGame.game);

  const isTelegram = process.env.REACT_APP_TARGET === 'telegram'

  return (
    <div className={cls.GameInfo}>
      <div className={cls.infoContainer}>
        <div className={cls.header}>
          <div className={cls.headerItem}>
            <img src={FolderIcon} alt={""} className={cls.folderIcon} />
            <h2>Игры</h2>
            <img alt={""} src={chooseGame.img} className={cls.avatarIconMini} />
            <h2>{chooseGame.name}</h2>
          </div>
          <svg onClick={() => dispatch(setIsVisible(false))} className={cls.CloseIcon} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="5.14062" y="5.14062" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="10.2812" y="10.2832" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="15.4258" y="15.4297" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="20.5742" y="20.5703" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="20.5742" y="10.2832" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="25.7188" y="5.14062" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="30.8594" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="10.2812" y="20.5703" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="5.14062" y="25.7168" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect y="30.8594" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="25.7188" y="25.7168" width="5.14286" height="5.14286" fill="currentColor"/>
            <rect x="30.8594" y="30.8594" width="5.14286" height="5.14286" fill="currentColor"/>
          </svg>
        </div>
        <div className={cls.container}>
          <img alt={""} src={chooseGame.img} className={cls.avatarIcon} />
          <div className={cls.short_descriptin}>{chooseGame.short_description.toUpperCase()}</div>
          <p className={cls.description}>{chooseGame.description}</p>
          <div className={cls.choose}>
            <div className={cls.choosePlay}>
              {chooseGame.link_read !== '' && (
                <a href={chooseGame.link_read} target={isTelegram? "_self" : "_blank"}>
                  Читать
                </a>
              )}
              <a href={chooseGame.link_play} target={isTelegram? "_self" : "_blank"}>
                Играть
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
